import * as React from "react"

import Seo from "../components/seo"
import { Link } from "gatsby"
import { useMediaQuery } from "react-responsive"

import * as errorStyles from "./404.module.css"
import * as menuStyles from "../components/menu.module.css"

const NotFoundPage = () => {
  const [show, setShow] = React.useState(false)

  const isSmallScreen = useMediaQuery({ query: "(max-width: 1199px)" })

  React.useEffect(() => {
    setTimeout(() => {
      setShow(true)
    }, 3000)
  }, [show])

  if (!show)
    return (
      <div className={errorStyles.errorContainer}>
        <Seo title="404: Not found" />
        <h1 className={errorStyles.heading}>404</h1>
        <p className={errorStyles.text}>
          <span className={errorStyles.span}>
            Oops, you took the wrong exit.
          </span>
          <span className={errorStyles.span}>Let me guide you back.</span>
        </p>
      </div>
    )
  return (
    <div className={errorStyles.errorContainer}>
      <Seo title="404: Not found" />
      {isSmallScreen && <h1 className={errorStyles.heading}>404</h1>}
      <nav>
        <ul className={menuStyles.list}>
          <li className={menuStyles.li}>
            <Link to="/work" className={menuStyles.link}>
              <span className={menuStyles.index}>01</span>
              <span className={menuStyles.menuItem}>Work.</span>
            </Link>
          </li>
          <li className={menuStyles.li}>
            <Link to="/about" className={menuStyles.link}>
              <span className={menuStyles.index}>02</span>
              <span className={menuStyles.menuItem}>About me.</span>
            </Link>
          </li>
          <li className={menuStyles.li}>
            <Link to="/contact" className={menuStyles.link}>
              <span className={menuStyles.index}>03</span>
              <span className={menuStyles.menuItem}>Say hello.</span>
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  )
}

export default NotFoundPage
